<template>
    <p v-if="skin === 'noSkin'" class="col-12 text-center mt-5">
        <font-awesome-icon icon="fa-solid fa-triangle-exclamation" size="2xl" beat class="my-4" /><br /><br />
        We are sorry but something went wrong.
    </p>
    <dynamic componentPath="loader" folder="others" :addSkin="true" v-else-if="underMaintanance === false && (zimplerLoginWithToken || showloader === true || skin === null || configStore.config === null || perCallsStore.dictionaryObject === null || perCallsStore.promotionsObject === null || perCallsStore.pagesObject === null)" />
    <template v-else>
        <dynamic componentPath="_ac" folder="skins" :underMaintanance="underMaintanance" v-if="skin === 'ac'" />
        <dynamic componentPath="_lj" folder="skins" :underMaintanance="underMaintanance" v-if="skin === 'lj'" />
        <dynamic componentPath="_bz" folder="skins" :underMaintanance="underMaintanance" v-else-if="skin === 'bz'" />
        <dynamic componentPath="_ra" folder="skins" :underMaintanance="underMaintanance" v-else-if="skin === 'ra'" />
        <dynamic componentPath="_bo" folder="skins" :underMaintanance="underMaintanance" v-else-if="skin === 'bo'" />
        <dynamic componentPath="_zu" folder="skins" :underMaintanance="underMaintanance" v-else-if="skin === 'zu'" />
        <dynamic componentPath="_se" folder="skins" :underMaintanance="underMaintanance" v-else-if="skin === 'se'" />
        <div class="black-backdrop" @click="onBlackBackdropClick()"></div>
        <dynamic componentPath="alerts" folder="others" v-if="myStore.alertInfo" :translatedText="myStore.alertInfo.translatedText" :text="myStore.alertInfo.text" :classes="myStore.alertInfo.classes" />
        <dynamic componentPath="cookiePolicyPopUp" folder="others" v-if="myStore.showCookiePolicyPopUp" />
        <dynamic componentPath="incognitoBrowserPopUp" folder="others" v-if="myStore.showIncognitoBrowserPopUp" />

        <!-- Christmas
        <div class="snowflakes" aria-hidden="true" v-if="showModal !== true">
            <div class="snowflake">❅</div>
            <div class="snowflake">❅</div>
            <div class="snowflake">❆</div>
            <div class="snowflake">❄</div>
            <div class="snowflake">❅</div>
            <div class="snowflake">❆</div>
            <div class="snowflake">❄</div>
            <div class="snowflake">❅</div>
            <div class="snowflake">❆</div>
            <div class="snowflake">❄</div>
        </div> -->
    </template>
</template>

<script>
import { getCookie, createCookie, setModalVisibility, setSideMenuVisibility, loadAnyScript, filterGameProviders, debounce } from "./helpers/helpers";
import { setRefreshTokenTimer, logOut } from "./helpers/servicesHelpers";
import { addGtmIFrame, createGtm, pushGtmEvent } from "./helpers/gtm_helpers";
import { openAiTranslations } from "./services/axiosInstances";
import { getLocation } from "./services/newPlatform/axiosNewPlatformRequests";
import { getLobbies, getBalances, getUser, getUserCampaignBonuses, getLiveGameStats, getUserBox, paymentConnection, loginWithToken } from "./services/tma/axiosTmaRequests";
// import { getLocation, getLobbies, getBalances, getUser, getUserCampaignBonuses, getLiveGameStats, getUserBox, paymentConnection, loginWithToken } from "./services/tma/axiosTmaRequests";
import { getDictionaries, getPromotions, getLobbiesInfo, getPages, getContentVersion, getLoggedOutCampaigns, getVipData, getWebsiteBlocks, getWheelRewards, getBannerItems } from "./services/strapi/axiosStrapiRequests";
import seoRelated from "./helpers/seo_helpers";
import * as Sentry from "@sentry/vue";
import ExcludedGamesList from "./assets/excludedGamesList";

export default {
    name: "App",
    data: function () {
        return {
            showloader: true,
            skin: null,
            initialCallFired: false,
            contentVersionCheckDone: false,
            evolutionGames: [],
            showModal: false,
            zimplerLoginWithToken: false,
            underMaintanance: false,
        };
    },
    created: function () {
        //Getting required data
        this.skin = this.getSkin();
        if (this.skin !== "noSkin") {
            this.configStore.initConfig(this.skin);
            if (this.underMaintanance) {
                addGtmIFrame(import.meta.env.VITE_GTM_ID);
                createGtm(import.meta.env.VITE_GTM_ID);
                setTimeout(
                    function () {
                        pushGtmEvent({ event: "LIVE_AGENT_VISIT_TRIGGER", skin: this.configStore.config.skin, liveAgentId: this.configStore.config.liveAgentId, liveAgentBtnId: this.configStore.config.languages[0].defaultChatButtonId });
                    }.bind(this),
                    1000,
                );
            } else {
                this.loadScripts();

                getContentVersion(this.myStore, this.skin, this.callsStore).then((latestVersion) => {
                    const currentVersion = getCookie("contentVersion");
                    if (currentVersion === "" || currentVersion !== `${latestVersion}`) {
                        // Clear persisted calls store data
                        createCookie("contentVersion", latestVersion, 15);
                        this.perCallsStore.$reset();
                    }
                    this.contentVersionCheckDone = true;
                    this.configStore.initConfig(this.skin);
                    this.$forceUpdate();
                });

                // I AM TEMPRARILY REPLACING THIS WITH CLOUDFLARE COUNTRY AND IP (BUT I DON'T HAVE ALL THE INFORMATION LIKE THE POSTCODE)
                // setTimeout(
                //     function () {
                //         getLocation(this.callsStore, this.myStore, this.$router, this.skin, this.perCallsStore).then((location) => this.$forceUpdate());
                //     }.bind(this),
                //     300,
                // );
                // getLocation(this.callsStore, this.myStore, this.$router, this.skin, this.perCallsStore).then((location) => this.$forceUpdate());

                if (getCookie("accessToken") !== "" && getCookie("expiresAt") !== "" && getCookie("refreshToken") !== "" && getCookie("userId") !== "") {
                    getUser(getCookie("accessToken"), getCookie("userId"), this.callsStore, this.myStore, this.$router, this.skin, this.perCallsStore).then(() => {
                        if (!this.callsStore.userObject.email && !this.callsStore.userObject.phone) {
                            this.$router.push("/play");
                            setTimeout(
                                function () {
                                    this.myStore.setBlockNavigation(true);
                                }.bind(this),
                                500,
                            );
                        }
                        getBalances(getCookie("accessToken"), getCookie("userId"), this.callsStore, this.myStore, this.$router, this.skin, this.perCallsStore);
                    });
                    setRefreshTokenTimer(getCookie("expiresAt"), getCookie("refreshToken"), getCookie("accessToken"), getCookie("userId"), this.callsStore, this.myStore, this.skin, this.perCallsStore);

                    let query = `userId: "${getCookie("userId")}", orderBy: {field:createdAt, direction:DESCENDING}, type:DEPOSIT, first:2, status:SUCCEEDED`;
                    paymentConnection(query, true, this.myStore, this.callsStore, getCookie("accessToken"), this.$router, this.configStore.config.skin, this.perCallsStore).then((response) => {
                        if (response.success && response.data.length === 1 && getCookie("depositedCustomer") !== "true") {
                            // GTM - FIRST DEPOSIT Triggers
                            if (this.configStore.config.blueMediaCampaignId) {
                                pushGtmEvent({ event: "BLUE_FIRST_DEPOSIT_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), blueMediaCampaignId: this.configStore.config.blueMediaCampaignId });
                            }
                            if (getCookie("affiliateId") !== "") {
                                pushGtmEvent({ event: "GA4_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), eventName: "First_Deposit_Success" });
                            }
                        }

                        if (response.success && response.data.length > 0) {
                            createCookie("depositedCustomer", "true", 1000);
                        } else {
                            createCookie("depositedCustomer", "false", 1000);
                        }
                    });
                } else {
                    logOut(this.callsStore, this.myStore, this.$router, true, this.skin, this.perCallsStore);
                }
            }
        }
    },
    updated: function () {
        if (this.initialCallFired === false && this.callsStore.locationObject && this.contentVersionCheckDone) {
            if (this.configStore.config.zimpler && this.callsStore.countryCode === "fi") {
                this.zimplerLoginWithToken = true;
                setTimeout(
                    function () {
                        if (this.$route.query.idp_pid && this.$route.query.idp_token) {
                            // IN IFRAME - Zimpler Play or Deposit and Play
                            loginWithToken(this.configStore.config, this.$route.query.idp_token, this.callsStore, this.myStore, this.router, this.perCallsStore).then((response) => {
                                if (response.success) {
                                    window.parent.postMessage({ message: "ZimplerLoginWithTokenSuccess" }, "*");
                                }
                            });
                        } else if (this.$route.query.iFrame) {
                            // IN IFRAME - Payment Redirect Uri In iFrame
                            window.parent.postMessage({ message: "PaymentRedirectUriInIFrame" }, "*");
                        } else if (this.$route.query.idp_error) {
                            // IN IFRAME
                            window.parent.postMessage({ message: "ZimplerErrorCancelled" }, "*");
                        } else {
                            // Not In IFrame
                            this.zimplerLoginWithToken = false;
                            window.addEventListener(
                                "message",
                                function (e) {
                                    // if (e.data && e.data.message && e.data.message === "ZimplerLoginWithTokenSuccess") {
                                    if (e.data && e.data.message) {
                                        setTimeout(
                                            function () {
                                                document.location.href = "/";
                                            }.bind(this),
                                            3000,
                                        );
                                    }
                                },
                                false,
                            );
                        }
                    }.bind(this),
                    1500,
                );
            }

            // Language Related
            let languageCode = getCookie("languageCode");
            let cmsLanguageCode = getCookie("cmsLanguageCode");
            let language = getCookie("language");

            if (languageCode === "" || languageCode === undefined || languageCode === "undefined") {
                // If language is not yet set in cookies
                this.configStore.config.languages.some((lang) => {
                    if (lang.code === this.callsStore.countryCode) {
                        // And the user location matches one of our site local codes
                        languageCode = lang.code;
                        cmsLanguageCode = lang.strapiCode;
                        language = lang.language;
                        return true;
                    }
                });
            }

            // If language is still empty
            if (languageCode === "" || languageCode === undefined || languageCode === "undefined") {
                languageCode = "en";
                cmsLanguageCode = "en";
                language = "English";
            }

            if (getCookie("languageCode") !== languageCode) {
                this.perCallsStore.$reset();
                createCookie("languageCode", languageCode, 365);
                createCookie("cmsLanguageCode", cmsLanguageCode, 365);
                createCookie("language", language, 365);
            }

            this.configStore.config.languages.some((language) => {
                if (language.code === languageCode) {
                    this.configStore.addToConfig({ currentLanguage: language });
                    return true;
                }
            });

            // Getting all lobbies info from Strapi
            // getLobbiesInfo(this.skin, this.perCallsStore, this.myStore).then((lobbiesInfo) => {
            //     if (lobbiesInfo.fromPersistedLocalStorage === false || this.perCallsStore.tmaLobbiesArray.length < 1) {
            //         let requiredLobbyIds = [];
            //         lobbiesInfo.strapiLobbiesArray.map((lobbyInfo) => {
            //             requiredLobbyIds.push(lobbyInfo.attributes.lobbyId);
            //         });

            //         // Getting lobbies from TMA (One by one as this was quicker)
            //         if (requiredLobbyIds.length > 0) {
            //             const requiredLobbyIdsLength = requiredLobbyIds.length;
            //             let flag = 0;
            //             let tmaLobbies = [];

            //             requiredLobbyIds.map((id) => {
            //                 getLobbies(this.perCallsStore, this.skin, id, this.myStore, this.callsStore, this.$router).then((lobby) => {
            //                     flag = flag + 1;
            //                     if (lobby[0]) {
            //                         // Filter Games Section
            //                         let enabledGames = [];
            //                         lobby[0].brandGames.map((game) => {
            //                             // If game is not in the ExcludedGamesList and game is enabled
            //                             if (ExcludedGamesList.indexOf(game.name) === -1 && game.enabled) {
            //                                 enabledGames.push(game);
            //                             }
            //                         });
            //                         lobby[0].brandGames = enabledGames;
            //                         lobby[0].filteredBrandGames = enabledGames;

            //                         // Filter Evolution Games Section
            //                         let evolutionGames = [];
            //                         if (lobby[0].id.indexOf("all-games") !== -1) {
            //                             lobby[0].brandGames.map((game) => {
            //                                 //Find Evolution games for evoApi
            //                                 if (game.studio === "EVOLUTION") {
            //                                     evolutionGames.push(game);
            //                                 }
            //                             });
            //                         }
            //                         this.perCallsStore.setEvolutionGamesArray(evolutionGames);

            //                         tmaLobbies.push(lobby[0]);
            //                     }

            //                     if (flag === requiredLobbyIdsLength) {
            //                         this.createLobbiesOrder(lobbiesInfo.strapiLobbiesArray, tmaLobbies);
            //                     }
            //                 });
            //             });
            //         }
            //     } else {
            //         this.prepareGameProvidersToBlock();
            //         this.getLiveGameStats();
            //     }
            // });

            getDictionaries(this.perCallsStore, this.myStore);
            getPages(this.perCallsStore, this.skin, this.$router, this.myStore);
            // getWebsiteBlocks(this.perCallsStore, this.skin, this.myStore);

            // Wheel of Fortune
            // if (this.configStore.config.wheelOfFortune) {
            //     getWheelRewards(this.perCallsStore, this.myStore, this.skin);
            //     if (this.myStore.loggedIn) {
            //         setTimeout(
            //             function () {
            //                 getUserBox(this.skin, this.myStore, this.perCallsStore, this.callsStore, this.$router);
            //             }.bind(this),
            //             15000,
            //         );
            //     }
            // }

            //Required Calls
            if (this.callsStore.countryCode && (this.callsStore.countryCode !== "se" || this.configStore.config.allowBonusesInSE)) {
                setTimeout(
                    function () {
                        if (this.configStore.config.vipPage) {
                            // Get VIP data from Strapi
                            getVipData(this.skin, this.perCallsStore, this.myStore);
                            // if (this.myStore.loggedIn) {
                            //     // Get VIP Progress from TMA
                            //     getVipProgress(getCookie("userId"), this.skin, this.myStore, getCookie("accessToken"), this.perCallsStore, this.callsStore, this.$router);
                            // }
                        }

                        if (this.myStore.loggedIn) {
                            getUserCampaignBonuses(getCookie("userId"), this.skin, this.myStore, getCookie("accessToken"), this.perCallsStore, this.callsStore, this.$router).then((response) => {
                                if (response.success && response.campaignIdList) {
                                    getPromotions(this.perCallsStore, this.callsStore, response.campaignIdList, this.skin, this.$router, this.myStore);
                                    getBannerItems(this.perCallsStore, this.skin, this.myStore, this.callsStore, response.campaignIdList);
                                }
                            });
                        } else {
                            getLoggedOutCampaigns(this.skin, this.perCallsStore, this.myStore).then((campaignIdList) => {
                                if (campaignIdList) {
                                    getPromotions(this.perCallsStore, this.callsStore, campaignIdList, this.skin, this.$router, this.myStore);
                                    getBannerItems(this.perCallsStore, this.skin, this.myStore, this.callsStore, campaignIdList);
                                }
                            });
                        }
                    }.bind(this),
                    100,
                );
            } else {
                this.perCallsStore.setPromotionsObject({});
            }

            //GTM Initialisation and SEO Related
            addGtmIFrame(import.meta.env.VITE_GTM_ID);
            createGtm(import.meta.env.VITE_GTM_ID);
            seoRelated(this.configStore.config);

            if (this.skin !== "bo" && this.skin !== "lj") {
                this.myStore.setCookiePolicyPopUp();
            }
            this.myStore.setIncognitoBrowserPopUp();
            this.showloader = false;
            this.initialCallFired = true;
            setTimeout(
                function () {
                    Sentry.setTag("Skin", this.skin);
                    Sentry.setContext("Additional Data", {
                        Skin: this.skin,
                        Language: languageCode,
                        Country: this.callsStore.locationObject !== null ? this.callsStore.locationObject.country.name : "N/A",
                        CountryCode: this.callsStore.locationObject !== null ? this.callsStore.locationObject.country.code : "N/A",
                        Postcode: this.callsStore.locationObject !== null ? this.callsStore.locationObject.postcode : "N/A",
                        LoggedIn: this.myStore.loggedIn,
                        user: this.myStore.loggedIn ? this.callsStore.userObject : "No User Info",
                    });

                    if (this.myStore.loggedIn && this.callsStore.userObject) {
                        Sentry.setUser({ email: this.callsStore.userObject.email });
                    }

                    // Example: /?affid=12345&token=affiliateTest
                    if (this.$router.currentRoute.value.query.affid) {
                        const affiliateId = this.$router.currentRoute.value.query.affid;
                        createCookie("affiliateId", affiliateId, 15);
                        Sentry.setTag("From Affiliate", true);
                        Sentry.setTag("Affiliate Id", affiliateId);
                        Sentry.withScope(
                            function (scope) {
                                scope.setLevel("info");
                                Sentry.captureMessage(`${this.skin.toUpperCase()}-UserFrom-${affiliateId}`);
                            }.bind(this),
                        );
                    }

                    if (this.$router.currentRoute.value.query.token) {
                        createCookie("affiliateToken", this.$router.currentRoute.value.query.token, 15);
                    }

                    // GTM - VISIT Triggers
                    if (this.configStore.config.blueMediaCampaignId) {
                        pushGtmEvent({ event: "BLUE_VISIT_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), blueMediaCampaignId: this.configStore.config.blueMediaCampaignId });
                    }

                    if (this.configStore.config.pixGamingScript) {
                        pushGtmEvent({ event: "PIX_VISIT_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), pixGamingScript: this.configStore.config.pixGamingScript });
                    }
                    if (this.configStore.config.hotjarSkinId) {
                        pushGtmEvent({ event: "HOTJAR_VISIT_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), hotjarSkinId: this.configStore.config.hotjarSkinId });
                    }
                    if (this.configStore.config.liveAgentId) {
                        this.configStore.config.languages.some((language) => {
                            if (language.code === languageCode && language.defaultChatButtonId) {
                                pushGtmEvent({ event: "LIVE_AGENT_VISIT_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), liveAgentId: this.configStore.config.liveAgentId, liveAgentBtnId: language.defaultChatButtonId });
                                return true;
                            }
                        });
                    }
                    if (getCookie("affiliateId") !== "") {
                        pushGtmEvent({ event: "GA4_TRIGGER", skin: this.configStore.config.skin, affiliateId: getCookie("affiliateId"), eventName: `Website_Opened_${getCookie("affiliateId")}` });
                    }
                }.bind(this),
                2000,
            );
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler: function (newVal, oldVal) {
                this.showModal = newVal.meta && newVal.meta.showModal;
            },
        },
    },

    methods: {
        getSkin() {
            if (document.location.href.indexOf("amoncasino") !== -1 || document.location.href.indexOf("amon-casino") !== -1 || document.location.href.indexOf("amuncasino") !== -1 || document.location.href.indexOf("amun-casino") !== -1) {
                this.underMaintanance = true;
                return "ac";
            } else if (document.location.href.indexOf("betzino") !== -1) {
                this.underMaintanance = true;
                return "bz";
            } else if (document.location.href.indexOf("racasino") !== -1 || document.location.href.indexOf("ra-casino") !== -1) {
                this.underMaintanance = true;
                return "ra";
            } else if (document.location.href.indexOf("bofcasino") !== -1 || document.location.href.indexOf("bof-casino") !== -1) {
                this.underMaintanance = true;
                return "bo";
            } else if (document.location.href.indexOf("zumi") !== -1) {
                this.underMaintanance = true;
                return "zu";
            } else if (document.location.href.indexOf("spineazy") !== -1 || document.location.href.indexOf("spin-eazy") !== -1) {
                return "se";
            } else if (document.location.href.indexOf("letsjackpot") !== -1 || document.location.href.indexOf("lets-jackpot") !== -1) {
                this.underMaintanance = true;
                return "lj";
            } else if (document.location.href.indexOf("localhost") !== -1 || document.location.href.indexOf("bs-local") !== -1) {
                this.underMaintanance = true;
                return "ra";
            } else {
                return "noSkin";
            }
        },
        loadScripts() {
            if (this.configStore.config.apgSeal) {
                loadAnyScript(this.configStore.config.apgSeal, "apgSeal", (scriptStatus) => {});
            }

            if (this.configStore.config.xcmSeal) {
                loadAnyScript(this.configStore.config.xcmSeal, "xcmSeal", (scriptStatus) => {});
            }

            if (this.configStore.config.anjSeal) {
                loadAnyScript(this.configStore.config.anjSeal, "anjSeal", (scriptStatus) => {});
            }
        },
        createLobbiesOrder(lobbiesInfo, tmaLobbies) {
            let homeLobbyIdsArray = [];
            let menuLobbyIdsArray = [];
            let casinoLobbyIdsArray = [];
            let liveCasinoLobbyIdsArray = [];
            let tmaLobbiesArray = [];

            lobbiesInfo.map((lobbyInfo) => {
                tmaLobbies.some((lobby) => {
                    if (lobby.id === lobbyInfo.attributes.lobbyId) {
                        if (lobbyInfo.attributes.position && Object.keys(lobbyInfo.attributes.position).length !== 0 && lobbyInfo.attributes.position.indexOf("Home") !== -1) {
                            homeLobbyIdsArray = [...homeLobbyIdsArray, lobby.id];
                        }

                        if (lobbyInfo.attributes.position && Object.keys(lobbyInfo.attributes.position).length !== 0 && lobbyInfo.attributes.position.indexOf("Menu") !== -1) {
                            menuLobbyIdsArray = [...menuLobbyIdsArray, { id: lobby.id, name: lobby.name }];
                        }

                        if (lobbyInfo.attributes.lobbyType && Object.keys(lobbyInfo.attributes.lobbyType).length !== 0 && lobbyInfo.attributes.lobbyType.indexOf("casino") !== -1) {
                            casinoLobbyIdsArray = [...casinoLobbyIdsArray, { id: lobby.id, name: lobby.name }];
                        }

                        if (lobbyInfo.attributes.lobbyType && Object.keys(lobbyInfo.attributes.lobbyType).length !== 0 && lobbyInfo.attributes.lobbyType.indexOf("liveCasino") !== -1) {
                            liveCasinoLobbyIdsArray = [...liveCasinoLobbyIdsArray, { id: lobby.id, name: lobby.name }];
                        }

                        tmaLobbiesArray = [...tmaLobbiesArray, lobby];
                    }
                });
            });
            this.perCallsStore.setHomeLobbyIdsArray(homeLobbyIdsArray);
            this.perCallsStore.setMenuLobbyIdsArray(menuLobbyIdsArray);
            this.perCallsStore.setCasinoLobbyIdsArray(casinoLobbyIdsArray);
            this.perCallsStore.setLiveCasinoLobbyIdsArray(liveCasinoLobbyIdsArray);
            this.perCallsStore.setTmaLobbiesArray(tmaLobbiesArray);

            this.prepareGameProvidersToBlock();
            this.getLiveGameStats();
        },
        prepareGameProvidersToBlock() {
            console.log(this.callsStore.countryCode);
            let gameProvidersToBlock = [];
            if (this.callsStore.countryCode === "nl") {
                // Block NETENT and RED_TIGER in Netherland
                gameProvidersToBlock = [...gameProvidersToBlock, "RED_TIGER", "NETENT"];
            }

            if (this.callsStore.countryCode === "au") {
                // Block NETENT and EVOLUTION in AUSTRALIA
                gameProvidersToBlock = [...gameProvidersToBlock, "NETENT"];
            }

            if (this.callsStore.countryCode === "fr") {
                // Block Pragmatic in France
                gameProvidersToBlock = [...gameProvidersToBlock, "PRAGMATIC_PLAY"];
                // TODO: Unblocked for users with 4+ deposits --- check4PlusDeposits
                // If we need to block pp per skin we can use this: this.configStore.config.blockPPGames
            }

            if (this.callsStore.countryCode === "gb") {
                // Block EVO Related And Pragmatic
                gameProvidersToBlock = [...gameProvidersToBlock, "NETENT", "BIG_TIME_GAMING", "NOLIMIT_CITY", "RED_TIGER", "PRAGMATIC_PLAY"];
            }

            if (this.callsStore.countryCode !== "fr") {
                // Block EVOLUTION everywhere except of France
                gameProvidersToBlock = [...gameProvidersToBlock, "EVOLUTION"];
            }

            this.callsStore.setGameProvidersToBlock(gameProvidersToBlock);
            filterGameProviders(this.perCallsStore, this.callsStore, this.configStore, false);
        },
        // check4PlusDeposits(blockType) {
        //     const intervalId = setInterval(
        //         function () {
        //             if (this.callsStore.userCampaignsList[0]) {
        //                 const userCampaignsList = this.callsStore.userCampaignsList;
        //                 clearInterval(intervalId);

        //                 // if (blockType === "blockEvoPlus" && (userCampaignsList.indexOf("fEBZiG6OPcY5Ls5lpyC8") !== -1 || userCampaignsList.indexOf("OcIGWoOg9ZmyGw10o7gF") !== -1 || userCampaignsList.indexOf("9cqqtASiTIg32Z3P7rq6") !== -1 || userCampaignsList.indexOf("rd6b9nEv0wKqD4fepZ5a") !== -1 || userCampaignsList.indexOf("z79P3ca8ksQZzaHdMsEB") !== -1)) {
        //                 //     // CampaignId for Customers that Deposited 4 or more - BZ: fEBZiG6OPcY5Ls5lpyC8 - RA: OcIGWoOg9ZmyGw10o7gF - AC: 9cqqtASiTIg32Z3P7rq6 - BO: rd6b9nEv0wKqD4fepZ5a - LJ: z79P3ca8ksQZzaHdMsEB
        //                 //     this.callsStore.setFilterGameProvidersCommand("unblock");
        //                 // } else

        //                 if (blockType === "blockPragmatic" && (userCampaignsList.indexOf("w6qIZpewJGN6sGRsQWkx") !== -1 || userCampaignsList.indexOf("Uib4UmSto6HjaYgNSZ3G") !== -1 || userCampaignsList.indexOf("81P8tZuIWHAM1p4fduTq") !== -1 || userCampaignsList.indexOf("MSUkK345Xcik517jfj63") !== -1 || userCampaignsList.indexOf("pvVfu4oyAXDdsCqdvstF") !== -1)) {
        //                     // CampaignId for FR Customers that Deposited 4 or more - BZ: w6qIZpewJGN6sGRsQWkx - RA: Uib4UmSto6HjaYgNSZ3G - AC: 81P8tZuIWHAM1p4fduTq - BO: MSUkK345Xcik517jfj63 - LJ: pvVfu4oyAXDdsCqdvstF
        //                     this.callsStore.setFilterGameProvidersCommand("unblock");
        //                 } else {
        //                     this.callsStore.setFilterGameProvidersCommand(blockType);
        //                 }
        //             }
        //         }.bind(this),
        //         100,
        //     );
        // },
        getLiveGameStats() {
            setTimeout(
                function () {
                    this.getLiveGameStats();
                }.bind(this),
                60000,
            );

            if (this.evolutionGames.length === 0) {
                this.perCallsStore.evolutionGamesArray.map((game) => {
                    if (this.evolutionGames.indexOf(game.gameId) === -1 && game.gameId !== "live-golden-wealth-baccarat") {
                        this.evolutionGames.push(game.gameId);
                    }
                });
            }
            getLiveGameStats(this.skin, '["' + this.evolutionGames.join('","') + '"]', this.myStore, this.perCallsStore, this.callsStore, this.$router);
        },
        onBlackBackdropClick() {
            if (this.myStore.blockNavigation) {
                //Do Nothing
            } else if (this.$router.currentRoute.value.fullPath.indexOf("/register") !== -1) {
                this.myStore.setCloseRegisterAttempt(true);
            } else if (this.$router.currentRoute.value.fullPath.indexOf("/vip") !== -1) {
                for (let i = 1; i < 31; i++) {
                    $(`#level-${i}-info`).removeClass("visible");
                }
                $(".black-backdrop").removeClass("visible");
            } else {
                this.myStore.setCloseRegisterAttempt(false);
                setSideMenuVisibility(false);
                if (this.$route.meta.showModal) {
                    setModalVisibility(false, this.$router);
                }
            }
        },
    },
};
</script>

<style lang="scss">
// When Side menu is opened
.black-backdrop {
    visibility: hidden;
    opacity: 0;
    transition: 0.15s all;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.8);
    &.visible {
        visibility: visible;
        opacity: 1;
    }
}

/* customizable snowflake styling */
.snowflake {
    color: #fff;
    font-size: 1em;
    font-family: "Molle", cursive;
    text-shadow: 0 0 1px #000;
}

@-webkit-keyframes snowflakes-fall {
    0% {
        top: -10%;
    }
    100% {
        top: 100%;
    }
}
@-webkit-keyframes snowflakes-shake {
    0% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
    50% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px);
    }
    100% {
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}
@keyframes snowflakes-fall {
    0% {
        top: -10%;
    }
    100% {
        top: 100%;
    }
}
@keyframes snowflakes-shake {
    0% {
        transform: translateX(0px);
    }
    50% {
        transform: translateX(80px);
    }
    100% {
        transform: translateX(0px);
    }
}
.snowflake {
    position: fixed;
    top: -10%;
    z-index: 9999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    -webkit-animation-name: snowflakes-fall, snowflakes-shake;
    -webkit-animation-duration: 10s, 3s;
    -webkit-animation-timing-function: linear, ease-in-out;
    -webkit-animation-iteration-count: infinite, infinite;
    -webkit-animation-play-state: running, running;
    animation-name: snowflakes-fall, snowflakes-shake;
    animation-duration: 10s, 3s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count: infinite, infinite;
    animation-play-state: running, running;
}
.snowflake:nth-of-type(0) {
    left: 1%;
    -webkit-animation-delay: 0s, 0s;
    animation-delay: 0s, 0s;
}
.snowflake:nth-of-type(1) {
    left: 10%;
    -webkit-animation-delay: 1s, 1s;
    animation-delay: 1s, 1s;
}
.snowflake:nth-of-type(2) {
    left: 20%;
    -webkit-animation-delay: 6s, 0.5s;
    animation-delay: 6s, 0.5s;
}
.snowflake:nth-of-type(3) {
    left: 30%;
    -webkit-animation-delay: 4s, 2s;
    animation-delay: 4s, 2s;
}
.snowflake:nth-of-type(4) {
    left: 40%;
    -webkit-animation-delay: 2s, 2s;
    animation-delay: 2s, 2s;
}
.snowflake:nth-of-type(5) {
    left: 50%;
    -webkit-animation-delay: 8s, 3s;
    animation-delay: 8s, 3s;
}
.snowflake:nth-of-type(6) {
    left: 60%;
    -webkit-animation-delay: 6s, 2s;
    animation-delay: 6s, 2s;
}
.snowflake:nth-of-type(7) {
    left: 70%;
    -webkit-animation-delay: 2.5s, 1s;
    animation-delay: 2.5s, 1s;
}
.snowflake:nth-of-type(8) {
    left: 80%;
    -webkit-animation-delay: 1s, 0s;
    animation-delay: 1s, 0s;
}
.snowflake:nth-of-type(9) {
    left: 90%;
    -webkit-animation-delay: 3s, 1.5s;
    animation-delay: 3s, 1.5s;
}
</style>
